/**
* Generated automatically at built-time (2025-03-03T13:54:50.952Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "vinkallare",templateKey: "sites/88-709afc4b-b170-40dd-ba39-d7f6c9df0cca"};